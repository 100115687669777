:root {
    --pag-renk: #f3ecd1;
    --pmc-renk: #f3f3f3;
  }

.positionname {
    color: #000;
    font-size: 17px;
    font-weight: bold;
    display: inline-block;
    float: left;
    padding-left: 2px;
    padding-top: 2px;
    min-height: 12px;
}
.destination {
    color:#000;
    font-size:x-small;
    font-weight: bold;
    display:inline-block;
    float:right;
    padding-right: 2px;
    padding-top: 2px;
    min-height: 12px;
}
.extrainfo {
    color:#87888D;
    font-size:xx-small;
    display:inline-block;
    float:left;
    padding-left: 2px;
    padding-top: 2px;
    min-height: 12px;
}
.maxkgkg {
    color:#000;
    font-size:xx-small;
    display:inline-block;
    float:right;
    padding-right: 2px;
    padding-top: 2px;
    min-height: 12px;
}
.maxkgellibir {
    position: absolute;
    font-size: 15px;
    color: #000;
    top: 3px;
    font-weight: 500;
    left: 19px;
    text-align: center;
}
.uldtypepra {
    position: relative;
    font-size: 13px;
    font-weight: bold;
    color: #000000;
    top: 6px;
    left: 0px;
    text-align: center;
}

.kirkdord {

    background-color:#f8cfff;;
    width: 60px;
    height: 60px;
    border: 1px solid #000 !important;
    position: relative;
    top: -1px;
    left: 3px;

}

.uldnopra {
    position: relative;
    font-size: 13px;
    color: #000000;
    font-weight: bold;
    top: 3px;
    left: 0px;
   /* width: 79px;
    */
    text-align: center;
}
.uldkgpra {
    position: relative;
    font-size: 12px;
    color: #000000;
    top: 28px;
    text-align: center;
}
.uldkgpraf {
    position: relative;
    font-size: 12px;
    color: #000000;
    top: 12px;
    left: -10px;
    text-align: center;
}
.uldkgpga {
    position: relative;
    font-size: 11px;
    color: #000000;
    top: 1px;
    left: 0px;
    text-align: center;
}
.uldtype {
    position: relative;
    font-size: 13px;
    font-weight: bold;
    color: #000000;
    top: 5px;
    text-align: center;
}

.uldno {
    position: relative;
    font-size: 13px;
    color: #000000;
    font-weight: bold;
    top: 2px;
    text-align: center;
}
.uldkg {
    font-size: 12px;
    color: #000000;
    text-align: center;
    position: relative;
    top: 23px;
}


.nesz {
display:block;
width:100%;
height:100%;
}


.nexx {
display:block;
position: absolute;
}

.nexx1 {
    margin-left: 225px;
    }

.nexx2 {
    margin-left: 4px;
        }

        .nexx3 {
        margin-left: 8px;
    }
    .nexx4 {
    margin-bottom: -1px;margin-left: 7px;
}
.nexx44 {
margin-left: 9px;
}

.nexx5 {
margin-bottom: -1px;margin-left: -60px;
}
.nexx66 {
margin-top:10px;
}
.nexx77 {
margin-left: 66px;
}


.uldkgpmc {
    font-size: 12px;
    color: #000000;
    text-align: center;
    position: relative;
    top: 23px;
}
.dginfo {
    position: relative;
    font-size: 8.5px;
    color: #000000;
    top: -14px;
    text-align: center;
}
.dginfoake {
    position: relative;
    font-size: 8px;
    color: #000000;
    top: -22px;
    height: 11px;
    text-align: center;
}
.dginfopla {
    position: relative;
    font-size: 10px;
    color: #000000;
    top: 18px;
    height: 11px;
    text-align: center;
}
.dginfocenter {
    position: relative;
    font-size: 8.5px;
    color: #000000;
    top: -12px;
    text-align: center;
}
.floating {
    position: absolute;
    font-size: 10px;
    color: #000000;
    top: -48px;
    text-align: center;
    left: 3px;
}
.dginfosbs {
    position: relative;
    font-size: 8.5px;
    color: #000000;
    top: -14px;
    height: 11px;
    text-align: center;
}
.arrowupsingle {
    position: absolute;
    color: #000000;
    top: -62px;
    left: 26px;
}
.arrowrightsingle {
    position: absolute;
    top: -41px;
    left: 52px;
}
.arrowrightsingle2 {
    position: absolute;
    top: -41px;
    left: 58px;
}
.arrowleftsingle {
    position: absolute;
    top: -41px;
    left: 2px;
}
.arrowleftsingle2 {
    position: absolute;
    top: -41px;
    left: 2px;
}
.arrowdownsingle {
    position: absolute;
    top: -2px;
    left: 26px;
}
.arrowdownsingle2 {
    position: absolute;
    top: -3px;
    left: 30px;
}
.arrowupsingle2 {
    position: absolute;
    height: 6px;
    color: #000000;
    top: -62px;
    left: 30px;
}
.arrowupsbs {
    position: absolute;
    color: #000000;
    top: -63px;
    left: 39px;
}
.arrowleftsbs {
    position: absolute;
    top: -26px;
    left: 1px;
}
.arrowrightsbs {
    position: absolute;
    top: -26px;
    left: 80px;
}
.arrowdownsbs {
    position: absolute;
    top: -4px;
    left: 2px;
}
.arrowrightpra {
    position: absolute;
    top: -29px;
    left: 135px;
}
.arrowleftpra {
    position: absolute;
    top: -29px;
    left: 2px;
}
.arrowdownpra {
    position: absolute;
    top: 1px;
    left: 69px;
}
.arrowuppra {
    position: absolute;
    height: 6px;
    color: #000000;
    top: -64px;
    left: 69px;
}
.arrowuppga {
    position: absolute;
    color: #000000;
    top: -63px;
    left: 80px;
}
.arrowleftpga {
    position: absolute;
    top: -19px;
    left: 3px;
}
.arrowrightpga {
    position: absolute;
    top: -19px;
    left: 154px;
}
.arrowdownpga {
    position: absolute;
    top: 1px;
    left: 80px;
}
.uldtypesbs {
    position: relative;
    top: 7px;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
}

.uldnosbs {
    position: relative;
    font-size: 13px;
    color: #000000;
    font-weight: bold;
    top: 3px;
    text-align: center;
}
.uldkgsbs {
    position: relative;
    font-size: 12px;
    color: #000000;
    top: 10px;
    text-align: center;
}
.maxkgpga {
    position: absolute;
    font-size: 15px;
    font-weight: 600;
    color: #000;
    top: 6px;
    left: 131px;
    text-align: center;
}
.maxkgldpallet {
    position: absolute;
    width: 81px;
    font-size: 15px;
    font-weight: 600;
    color: #000;
    top: 12px;
    left: 18px;
    float: right;
}

.zzellibir {
    background-color: #efefdf;
    width: 50px;
    height: 50px;
    border: 1px solid #000 !important;
    top: -5px;
    right: -5px;
    position: relative;
}

.maxkgpgawhite {
    position: relative;
    font-size: 10px;
    color: #000;
    top: 6px;
    left: 54px;
    text-align: center;
}
.maxkgcenter {
    float: right;
    position: absolute;
    font-size: 15px;
    font-weight: 600;
    color: #000;
    top: 6px;
    left: 106px;
    text-align: right;
}
.maxkgcenterwhite {
    position: relative;
    font-size: 10px;
    color: #000;
    top: 10px;
    left: 45px;
    text-align: center;
}
.maxkgsbs {
    position: absolute;
    font-size: 15px;
    font-weight: 600;
    color: #000;
    top: 6px;
    left: 55px;
    text-align: center;
}
.maxkgsbswhite {
    position: relative;
    font-size: 10px;
    color: #000;
    top: -10px;
    left: 26px;
    text-align: center;
}
.maxkgsbswhiteasymmetric {
    position: relative;
    font-size: 10px;
    width: 28px;
    color: #000;
    top: 31px;
    left: 48px;
    text-align: center;
    background-color: #efff00;
}
.maxkgsinglepmc {
    position: absolute;
    font-size: 15px;
    color: #000;
    font-weight: 600;
    top: 24px;
    left: 34px;
    text-align: center;
}
.maxkgsinglepmcwhite {
    position: relative;
    font-size: 10px;
    color: #000;
    top: 0px;
    left: 17px;
    text-align: center;
}
.maxkgsinglepag {
    position: absolute;
    font-size: 15px;
    font-weight: 600;
    color: #000;
    top: 18px;
    left: 28px;
    text-align: center;
}
.maxkgsinglepagwhite {
    position: relative;
    font-size: 10px;
    color: #000;
    top: -1px;
    left: 14px;
    text-align: center;
}
.blacked {
    color: #000;
}
.maxkgake {
    position: absolute;
    font-size: 15px;
    font-weight: 600;
    color: #000;
    top: 12px;
    left: 25px;
    text-align: center;
}
.maxkgpla {
    position: relative;
    font-size: 8px;
    color: #87888D;
    top: -3px;
    left: 9px;
    text-align: center;
}
.maxkgplawhite {
    position: relative;
    font-size: 8px;
    color: #000;
    top: 41px;
    left: 9px;
    text-align: center;
}
.maxkgfirstpla {
    position: relative;
    font-size: 8px;
    color: #87888D;
    top: 75px;
    left: 9px;
    text-align: center;
}
.uldtypeake {
    position: relative;
    font-size: 12px;
    font-weight: bold;
    color: #000000;
    top: -2px;
    text-align: center;
}
.uldnoake {
    position: relative;
    font-size: 12px;
    color: #000000;
    font-weight: bold;
    top: -5px;
    left: 0px;
    text-align: center;
}
.uldkgake {
    position: relative;
    font-size: 11px;
    color: #000000;
    top: -2px;
    text-align: center;
}
.maxkgakewhite {
    display:none;
}
.maxkgeu {
    position: absolute;
    font-size: 15px;
    font-weight: 600;
    color: #000;
    top: 75px;
    left: 47px;
    text-align: center;
}

.basex {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
}

.basexx {
   background-color: #fff;
    
}

.uldtypeeu {
    position: relative;
    font-size: 10px;
    color: #000000;
    top: 12px;
    left: 0px;
    text-align: center;
}
.uldnoeu {
    position: relative;
    font-size: 8px;
    color: #000000;
    font-weight: bold;
    top: 11px;
    left: 0px;
    text-align: center;
}
.uldkgeu {
    position: relative;
    font-size: 9px;
    color: #000000;
    top: 11px;
    left: 0px;
    text-align: center;
}
.maxkgeuwhite {
    position: relative;
    font-size: 8px;
    color: #000;
    top: 18px;
    left: 9px;
    text-align: center;
}

.transit3 {
    height: 3px;
}
.bgblack {
    background-color: #000;
}

.uldtypeld {
    position: relative;
    font-size: 12px;
    font-weight: bold;
    color: #000000;
    top: 17px;
    text-align: center;
}
.uldtypeldssingle {
    position: relative;
    font-size: 13px;
    font-weight: bold;
    color: #000000;
    top: 5px;
    text-align: center;
}
.uldkgldld {
    font-size: 12px;
    color: #000000;
    text-align: center;
    position: relative;
    top: 16px;
}
.uldnold {
    position: relative;
    font-size: 12px;
    color: #000000;
    font-weight: bold;
    top: 17px;
    left: 0px;
    text-align: center;
}
.uldkgld {
    position: relative;
    font-size: 11px;
    color: #000000;
    top: 55px;
    text-align: center;
}
.singlepag {
    background-color: var(--pag-renk);
    width: 66px;
    height: 66px;
    border: 1px solid #000 !important;
}
.transitpag {
    background-color: #000;
}
.whitesinglepag {
    background-color: #fff;
    color: #000;
    width: 66px;
    height: 90px;
    border: 1px solid #000 !important;
}
.busysinglepag {
    background-color: var(--pag-renk);
    color: rgba(143,143,143,1.00);
    width: 66px;
    height: 90px;
    border: 1px solid #000 !important;
}
.rpag {
    margin-left: 0.32cm;
    margin-right: 0.15cm;
}
.tpag {
    margin-left: 0.15cm;
    margin-right: 0.15cm;
}
.singlepmc {
    background-color: #efefdf;
    width: 72px;
    height: 72px;
    border: 1px solid #000 !important;
    margin-left: 0px;
    margin-right: 0px;
}
.busysinglepmc {
    background-color: var(--pag-renk);
    color: rgba(143,143,143,1.00);
    width: 72px;
    height: 90px;
    border: 1px solid #000 !important;
}
.whitesinglepmc {
    background-color: #fff;
    width: 72px;
    height: 90px;
    border: 1px solid #000 !important;
}
.singlepmcld {
    background: linear-gradient(to top right, var(--pag-renk) 50%, #efefdf 50%);
    width: 92px;
    height: 83px;
    border: 1px solid #000 !important;
 
}
.busysinglepmcld {
    background-color: var(--pag-renk);
    width: 92px;
    height: 83px;
    border: 1px solid #000 !important;
    margin-left: 0px;
    margin-right: 0px;
}
.whitesinglepmcld {
    background-color: #fff;
    width: 92px;
    height: 83px;
    border: 1px solid #000 !important;
    margin-left: 0px;
    margin-right: 0px;
}
.centerpmc {
    background-color: #efefdf;
    width: 94px;
    height: 54px;
    border: 1px solid #000 !important;
}
.whitecenterpmc {
    background-color: #fff;
    color:  var(--pag-renk);
    width: 94px;
    height: 76px;
    border: 1px solid #000 !important;
}
.busycenterpmc {
    background-color: var(--pag-renk);
    color: rgba(143,143,143,1.00);
    width: 94px;
    height: 76px;
    border: 1px solid #000 !important;
}
.centercfr {
    background-color: #dbdae1;
    width: 150px;
    height: 54px;
    position: absolute;
    top: 154px;
    left: 259px;
    border: 1px solid #000 !important;
}
.busycentercfr {
    background-color: var(--pag-renk);
    color: rgba(143,143,143,1.00);
    width: 150px;
    height: 21px;
    position: absolute;
    top: 275px;
    left: 259px;
    border: 1px solid #000 !important;
}
.whitecentercfr {
    background-color: #fff;
    color:  var(--pag-renk);
    width: 150px;
    height: 96px;
    position: absolute;
    top: 275px;
    left: 259px;
    border: 1px solid #000 !important;
}
.noshow {
    display: none;
}
.busycenterfjr {
    background-color: #efefdf;
    width: 150px;
    height: 54px;
    position: absolute;
    top: 196px;
    left: 437px;
    border: 1px solid #000 !important;
}
.centerfjr {
    background-color: #dbdae1;
    width: 150px;
    height: 54px;
    position: absolute;
    top: 154px;
    left: 437px;
    border: 1px solid #000 !important;
}
.whitecenterfjr {
    background-color: #fff;
    color:  #000;
    width: 150px;
    height: 96px;
    border: 1px solid #000 !important;
    position: absolute;
    top: 275px;
    left: 437px;
}
.centerjlr {
    background-color: #dbdae1;
    width: 150px;
    height: 54px;
    position: absolute;
    top: 154px;
    left: 591px;
    border: 1px solid #000 !important;
}
.busycenterjlr {
    background-color: var(--pag-renk);
    color: rgba(143,143,143,1.00);
    width: 150px;
    height: 21px;
    position: absolute;
    top: 275px;
    left: 591px;
    border: 1px solid #000 !important;
}
.whitecenterjlr {
    background-color: #fff;
    color:  #000;
    width: 150px;
    height: 96px;
    position: absolute;
    top: 275px;
    left: 591px;
    border: 1px solid #000 !important;
}
.busycenterlpr {
    background-color: var(--pag-renk);
    color: rgba(143,143,143,1.00);
    width: 150px;
    height: 21px;
    position: absolute;
    top: 275px;
    left: 745px;
    border: 1px solid #000 !important;
}
.centerlpr {
    background-color: #dbdae1;
    width: 150px;
    height: 54px;
    position: absolute;
    top: 154px;
    left: 745px;
    border: 1px solid #000 !important;
}
.whitecenterlpr {
    background-color: #fff;
    color:  #000;
    width: 150px;
    height: 96px;
    position: absolute;
    top: 275px;
    left: 745px;
    border: 1px solid #000 !important;
}
.centercfg {
    background-color: #e5f7d5;
    width: 174px;
    height: 54px;
    position: absolute;
    top: 278px;
    left: 257px;
    border: 1px solid #000 !important;
}
.busycentercfg {
    background-color: var(--pag-renk);
    color: rgba(143,143,143,1.00);
    width: 174px;
    height: 21px;
    position: absolute;
    top: 370px;
    left: 257px;
    border: 1px solid #000 !important;
}
.whitecentercfg {
    background-color: #fff;
    color:  #000;
    width: 170px;
    height: 96px;
    position: absolute;
    top: 295px;
    left: 257px;
    border: 1px solid #000 !important;
}
.centerfjg {
    background-color: #e5f7d5;
    width: 174px;
    height: 54px;
    position: absolute;
    top: 278px;
    left: 439px;
    border: 1px solid #000 !important;
}
.busycenterfjg {
    background-color: var(--pag-renk);
    color: rgba(143,143,143,1.00);
    width: 174px;
    height: 21px;
    position: absolute;
    top: 370px;
    left: 439px;
    border: 1px solid #000 !important;
}
.whitecenterfjg {
    background-color: #fff;
    color:  #000;
    width: 170px;
    height: 96px;
    position: absolute;
    top: 295px;
    left: 439px;
    border: 1px solid #000 !important;
}
.centerjlg {
    background-color: #e5f7d5;
    width: 174px;
    height: 54px;
    position: absolute;
    top: 278px;
    left: 620px;
    border: 1px solid #000 !important;
}
.busycenterjlg {
    background-color: var(--pag-renk);
    color: rgba(143,143,143,1.00);
    width: 174px;
    height: 21px;
    position: absolute;
    top: 370px;
    left: 620px;
    border: 1px solid #000 !important;
}
.whitecenterjlg {
    background-color: #fff;
    color:  #000;
    width: 170px;
    height: 96px;
    position: absolute;
    top: 295px;
    left: 620px;
    border: 1px solid #000 !important;
}
.tablesbs {
    margin-top: 2px;
    margin-bottom: -5px;
}
.tableld {
    margin-top: 40px;
}
.sbspag {
    background-color: var(--pag-renk);
    width: 91px;
    height: 54px;
    border: 1px solid #000 !important;
}
.whitesbspag {
    background-color: #fff;
    color:  #000;
    width: 91px;
    height: 76px;
    border: 1px solid #000 !important;
}
.busysbspag {
    background-color: var(--pag-renk);
    color: rgba(143,143,143,1.00);
    width: 91px;
    height: 76px;
    border: 1px solid #000 !important;
}
.sbsregular {
    background: linear-gradient(to bottom right, var(--pag-renk) 50%, #efefdf 50%);
    width: 94px;
    height: 54px;
    border: 1px solid #000 !important;
}
.whitesbsregular {
    background-color: #fff;
    color:  #000;
    width: 94px;
    height: 76px;
    border: 1px solid #000 !important;
}
.busysbsregular {
    background-color: var(--pag-renk);
    color: rgba(143,143,143,1.00);
    width: 94px;
    height: 76px;
    border: 1px solid #000 !important;
}
.czx {
margin-top: 64px;
}

.pla {
    background-color: #efefdf;
    width: 0.952cm;
    height: 15px;
    border: 1px solid #000 !important;
}
.ake {
   
    background: linear-gradient(to bottom, #f0e2f0 50%, #ffeaff 50%);
    width: 60px;
    height: 60px;
    border: 1px solid #000 !important;
}
.ake2 {
    background-color: #FECEFF;
    background: linear-gradient(to bottom, #ffeaff 50%, #f0e2f0 50%);

    width: 60px;
    height: 60px;
    border: 1px solid #000 !important;
}
.whiteake {
    background-color: #fff;
    color:  #000;
    width: 60px;
    height: 60px;
    border: 1px solid #000 !important;
}
.busyake {
    background-color: var(--pag-renk);
    color: rgba(143,143,143,1.00);
    width: 60px;
    height: 60px;
    border: 1px solid #000 !important;
}
.maketopla {
    background-color: #efefdf;
    width: 60px;
    height: 120px;
    top: 84px;
    position: relative;
    border: 1px solid #000 !important;
    position: relative;
    margin-top: -108px;
}
.whitemaketopla {
    background-color: #fff;
    color:  #000;
    width: 39px;
    height: 101px;
    border: 1px solid #000 !important;
    position: relative;
    margin-top: -108px;
}
.busymaketopla {
    background-color: var(--pag-renk);
    color: rgba(143,143,143,1.00);
    width: 39px;
    height: 23px;
    border: 1px solid #000 !important;
    position: relative;
    margin-top: -108px;
}
.aketopla {
    background-color: #fff;
    color: rgba(143,143,143,1.00);
    width: 60px;
    height: 120px;
    border: 1px solid #000 !important;
    position: relative;
    margin-bottom: -60px;
}
.whiteaketopla {
    background-color: #fff;
    color:  #000;
    width: 39px;
    height: 101px;
    border: 1px solid #000 !important;
    position: relative;
    margin-top: -108px;
}
.busyaketopla {
    background-color: var(--pag-renk);
    color: rgba(143,143,143,1.00);
    width: 1.62cm;
    height: 3.24cm;
    border: 1px solid #000 !important;
    position: relative;
    margin-bottom: -61px;
}
.elliuc {
    background-color: #efefdf;
    width: 81px;
    height: 121px;
    border: 1px solid #000 !important;
    position: relative;
    margin-bottom: -62px;
}
.whiteelliuc {
    background-color: #fff;
    color:  #000;
    width: 60px;
    height: 119px;
    border: 1px solid #000 !important;
    position: relative;
    margin-bottom: -60px;
}
.centertable {
    margin-top: 68px;
    margin-bottom: 25px;
}
.pmctable {
    margin-top: 5px;
    margin-bottom: 5px;
}

.sbstablez {
margin-top: 70px;
}